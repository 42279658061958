import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const NewContactUs= ()=>{
  const { t } = useTranslation();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <section className="secbg">
    <div className="container p-4 py-md-5 px-md-5">
      <div className="row">
        <div className="col-12 px-md-5">
          <h2 className="fw-semibold mt-md-5 text-white">
            {t('frp4')}
          </h2>
          <p className=" imeiblacklist m-0 mt-3 mb-2 text-white">
            {t('frp5')} <Link className="text-white" to={'/premium-blacklist-checker'} onClick={scrollToTop}>{t('frp6')}</Link>{t('frp7')}
          </p>
          <p className=" imeiblacklist m-0 mb-3 text-white">
            {t('frp8')} <span className="fw-semibold">{t('frp9')}</span>{t('frp10')}
          </p>
        </div>
      </div>
    </div>
  </section>
  )
}

export default NewContactUs