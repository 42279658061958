import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import "./Videos.css";
import cookies from "js-cookie";

export const ServicePromotionVideos = ({ youtubeUrls = [], title }) => {
  console.log('youtubeUrls: ', youtubeUrls);
  const [videoEmbedId, setVideoEmbedId] = useState("");
  const [thumbnailUrls, setThumbnailUrls] = useState([]);
  const currentLanguageCode = cookies.get("i18next") || "en";

  // YouTube player options
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 0,
    },
  };

  // Function to extract video ID from YouTube URL
  const getVideoIdFromUrl = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|\/u\/\w\/|embed\/|watch\?v=|\&v=|watch\?.*v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : "";
  };

  useEffect(() => {
    // Generate video IDs and thumbnail URLs for all YouTube URLs
    if (Array.isArray(youtubeUrls)) {
      const videoIds = youtubeUrls.map((url) => getVideoIdFromUrl(url));
      setVideoEmbedId(videoIds[0]); // Set the first video as default
      const generatedThumbnailUrls = videoIds.map((id) => `https://img.youtube.com/vi/${id}/0.jpg`);
      setThumbnailUrls(generatedThumbnailUrls);
    }
  }, [youtubeUrls]);

  return (
    <div>
      <section className="videos-slider py-md-5 pt-5 pb-0">
        <div className="container pb-5">
          <h2 className="text-center display-6 fw-bold mb-5 check-out-video">
            {title}
          </h2>

          <div className="wistia_responsive_padding p-4">
            <div id="video-slider" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-inner">
                {/* Carousel Items */}
                {youtubeUrls?.map((url, index) => (
                  <div
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                    key={index}
                  >
                    <YouTube videoId={getVideoIdFromUrl(url)} opts={opts} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicePromotionVideos;
