import axios from "axios";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 500000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true, // Ensure cookies are included in requests
});


// Function to fetch CSRF token
const getCsrfToken = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/csrf-token`, {
      withCredentials: true, // Include cookies
    });
    return response.data.csrfToken; // Assuming the CSRF token is returned in the response
  } catch (error) {
    console.error("Failed to fetch CSRF token:", error);
    throw error;
  }
};

// Add a request interceptor to include CSRF token
instance.interceptors.request.use(
  async (config) => {
    if (["post", "put", "patch", "delete"].includes(config.method)) {
      const csrfToken = await getCsrfToken();
      config.headers["X-CSRF-Token"] = csrfToken; // Include CSRF token in headers
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Extract the response body for convenience
const responseBody = (response) => response.data;

const requests = {
  get: (url, params) => instance.get(url, { params }).then(responseBody),
  post: (url, body, headers) => instance.post(url, body, { headers }).then(responseBody),
  put: (url, body) => instance.put(url, body).then(responseBody),
  delete: (url, body) => instance.delete(url, { data: body }).then(responseBody),
};

export default requests;

