import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./unlock-service.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Link } from "react-router-dom";
import CheckServiceCheckout from "../stripe/CheckServiceCheckout";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import Skeleton from "@mui/material/Skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import { createPaymentIntent } from "../../_services/checkService";

const UnlockService = () => {
  const { formValue, productData } = useMemo(
    () => JSON.parse(localStorage.getItem("state")) || { formValue: {}, productData: {} },
    []
  );
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_KEY);
  const [clientSecret, setClientSecret] = useState(null);
  const [selected, setSelected] = useState("");
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const fetchClientSecret = useCallback(async () => {
    try {
      setLoading(true);
      const body = {
        serviceId: productData.serviceId,
        provider: productData.provider,
        price: productData.price,
        languageId: currentLanguageCode,
        name: productData.title,
        email: formValue.email,
        imei: formValue.imei,
        callback: formValue.callback,
      };
      const res = await createPaymentIntent(body);
      const { clientSecret, customer } = res
      setClientSecret(clientSecret);
      setCustomer(customer);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [formValue, productData, currentLanguageCode]);
  const selectPaymentMethod = useCallback(async (type) => {
    setSelected(type);
    if (!clientSecret && type === "card" && Object.keys(formValue).length > 0) {
      await fetchClientSecret();
    }
  }, [clientSecret, formValue, fetchClientSecret]);

  useEffect(() => {
    const inputs = document.querySelectorAll(".menu-input");
    const contents = document.querySelectorAll(".content");
    const menu = document.querySelector("#menu ul");
    inputs.forEach((input, index) => {
      input.addEventListener("change", () => {
        menu.classList.add("hide-menu");
        contents.forEach(content => content.classList.remove("show-content"));
        contents[index].classList.add("show-content");
      });
    });
    contents.forEach((content, index) => {
      const backBtn = content.querySelector("a");
      backBtn.addEventListener("click", (e) => {
        e.preventDefault();
        contents[index].classList.remove("show-content");
        menu.classList.remove("hide-menu");
        inputs[index].checked = false;
      });
    });
    return () => {
      inputs.forEach((input, index) => {
        input.removeEventListener("change", () => { });
      });
      contents.forEach((content, index) => {
        const backBtn = content.querySelector("a");
        backBtn.removeEventListener("click", () => { });
      });
    };
  }, []);
  const imageSrc =
  productData?.url === "premium-blacklist-checker" ||
  productData?.url === "imei-blacklist-checker"
    ? "/images/unlockriver_checkserviceblacklist.jpeg"
    : "/images/unlockriver_checkservice.jpg";

  return (
    <div>
      <div className="container-fluid p-0 bg-light-conatiner">
        <div className="row py-0 px-md-5 m-0 gap-4 justify-content-center">
          <div className="col-md-12 col-lg-4 py-md-5 pt-3 pb-0">
            <div id="menu">
              <ul>
                <Link className="text-decoration-none text-dark" to={"/unlock-phone"}><i className="fa fa-chevron-left text-dark"></i> {t("Reviewback")}</Link>
                <h5 className="pt-3 fw-semibold pb-3">{t("Payment")}</h5>
                {/* <p>{t("Paymentp1")}</p> */}
                <li onClick={() => selectPaymentMethod("card")}>
                  <input type="radio" name="menu-item" id="item-2" className="menu-input" onClick={(e) => e.stopPropagation()} // Prevents the radio button from triggering the parent li's onClick event
                  />
                  <label for="item-2" className="d-flex justify-content-between" >
                    <div>
                      <img className="me-3" src="/images/payment2.png" alt="Payment" width={'80'} />{t("Paymenth1")}
                    </div>
                    <div>
                      <i className="fa fa-chevron-right text-dark"></i>
                    </div>
                  </label>

                </li>
              </ul>
              <div id="content-1" className="content">
                <a className="text-decoration-none text-dark" href="#" id="back-1" ><i className="fa fa-chevron-left text-dark"></i> {t("Reviewback")}</a>
                <h5 className="pt-3 fw-semibold">{t("Paymenth3")}</h5>
                <h5>{t("Paymentp2")}</h5>

                <form className="pt-3 pt-md-0">
                  {
                    clientSecret && selected === "card" &&
                    <Elements
                      stripe={stripePromise}
                      options={{
                        clientSecret: clientSecret,
                        locale: currentLanguageCode,
                      }}
                    >
                      <CheckServiceCheckout
                        customer={customer}
                        clientSecret={clientSecret}
                        productData={productData}
                        formValue={formValue}
                      />
                    </Elements>
                  }
                </form>

              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4 py-md-4 pt-0 mt-0 ps-md-5 pe-md-3 px-4 mt-md-4 mt-md-0 order-details-col">
            <div className="p-md-0 p-0">
              <div className="row p-0 flex-nowrap">
                <h6 className="fw-bold text-secondary lh-base pb-0">{t("Reviewyo")}</h6>
              </div>

              <div className="row border-bottom pt-3 pb-3 flex-md-nowrap">
                <div className="col-md-4 ps-0">
                  {productData ? (
                    <img
                      loading="lazy"
                      className="modal-img shadow"
                      alt={""}
                      src={`/images/unlockriver_checkserviceblacklist.jpeg`}

                    />
                  ) : (
                    <Skeleton variant="rectangular" width={150} height={150} />
                  )}
                </div>
                <div className="col-md-8 px-0 py-md-0 py-3">
                  <h6 className="fw-semibold lh-base me-3">
                    {productData?.title}
                  </h6>
                  <h6 className="fw-normal lh-base small">
                  </h6>
                  <h6 className="fw-normal lh-base small">
                    <span className="text-secondary">IMEI:</span> {formValue?.imei}
                  </h6>
                  <h6 className="fw-normal lh-base small">

                  </h6>
                </div>
              </div>
              <div className="row pt-3 pb-0 custom-our-price">
                <div className="col-md-8 ps-0 pe-0 custom-our-price-right">
                  <h4 className=" text-secondary lh-base pb-0">{t("Reviewyoh1")}</h4>
                </div>
                <div className="col-md-4 pe-0 custom-our-price-right">
                  <h4 className=" text-secondary lh-base cus-price-amount pb-2">
                    {t("Reviewyoh11")}
                  </h4>
                </div>
              </div>
              <div className="row border-bottom pt-3 pb-2 custom-our-price">
                <div className="col-md-8 ps-0 pe-0 custom-our-price-right">
                  <h4 className=" text-secondary lh-base pb-2">{t("UnpC4")}</h4>
                  <h4 className=" text-secondary lh-base">{t("Reviewyoh3")}</h4>
                </div>
                <div className="col-md-4 pe-0 custom-our-price-right">
                  <h4 className=" text-secondary lh-base cus-price-amount pb-2">
                    ${productData?.price} USD
                  </h4>
                  <h4 className="text-secondary lh-base cus-price-amount">
                    $0
                  </h4>
                </div>
              </div>
              <div className="row pt-3 pb-2 custom-our-price">
                <div className="col-md-8 ps-0 pe-0 custom-our-price-right">
                  <h6 className="fw-bold text-dark lh-base pb-2">Total:</h6>
                </div>
                <div className="col-md-4 pe-0 custom-our-price-right">
                  <h6 className="fw-bold text-dark lh-base cus-price-amount pb-2 text-end">
                    ${productData?.price} USD
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnlockService;
