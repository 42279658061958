import React, { useState, useRef, useCallback, useEffect, useMemo } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useLoading } from "../../_shared/LoadingContext";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { getCustomProductById } from "../../_services/unlockPhone";
import "./samsungAccRemoval.css";
import httpRequest from '../../_services/httpServices'
import ServicePromotionVideos from "../../shared/videos/ServicePromotionVideos";
import Helper from "../../shared/NewHowitWork/Helper";

const SITE_KEY = process.env.REACT_APP_SITE_KEY;
const GoogleFRPSection = () => {
  const { t } = useTranslation();
  const [currentLanguageCode, setCurrentLanguageCode] = useState(cookies.get("i18next") || "en");
  return (
    <section className="py-md-5 py-4">
      <div className="container py-md-5">
        <div className="row row justify-content-center  align-items-center">
          <div className="col-md-4 col-12">
            <img className="img-fliud" src="/images/frplock.png" alt="frplock" width="100%" />
          </div>
          <div className="col-md-7 col-12 px-md-5 px-3">
            <h2 className="fw-semibold display-5 mt-md-5 mt-4">
              {t('frp1')}
            </h2>
            <p className=" imeiblacklist2 m-0 my-3">
              {t('frp2')}
            </p>
            <p className=" imeiblacklist2 m-0 my-3">
              {t('frp3')}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
const WhyChooseUs = ({ title, whyChooseList = [], bgClass }) => {
  const { t } = useTranslation();
  return (
    <section className={`why-choose-us ${bgClass}`}>
      <div className="container p-3 py-md-5">
        <div className="row justify-content-center d-flex align-items-center">
          <div className="col-12 col-md-6 px-3 px-md-0">
            <h2 className="display-6 fw-bold">{title}</h2>
            <ul className="choose-us-list py-4 ps-3">
              {whyChooseList.length > 0 &&
                whyChooseList.map((item, index) => (
                  <li key={index} className="ps-2 list-title pb-4">
                    <span className="imeiblacklist fw-bold">{t(item.title)}</span>
                    <p className="pt-2">{t(item.text)}</p>
                  </li>
                ))}
            </ul>
          </div>

          <div className="col-10 col-md-5 text-center pb-4 py-md-0">
            <img
              className="img-fluid p-0 p-md-0"
              src="/images/Frame.png"
              alt="about-mission"
              width={"500"}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const NewContactUs = () => {
  const { t } = useTranslation();
  return (
    <section className="secbg">
      <div className="container p-4 py-md-5 px-md-5">
        <div className="row">
          <div className="col-12 px-md-5">
            <h2 className="fw-semibold mt-md-5 text-white">
              {t('frp4')}
            </h2>
            <p className=" imeiblacklist m-0 mt-3 mb-2 text-white">
              {t('frp5')} <a className="text-white" href="#" >{t('frp6')}</a>{t('frp7')}
            </p>
            <p className=" imeiblacklist m-0 mb-3 text-white">
              {t('frp8')} <span className="fw-semibold">{t('frp9')}</span>{t('frp10')}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

const SamsungAccRemoval = () => {
  const { t } = useTranslation();
  const [currentLanguageCode, setCurrentLanguageCode] = useState(cookies.get("i18next") || "en");
  const [youtubeUrls, setYoutubeUrls] = useState(null);
  // Define localized carrier data
  const localizedManufacturerData = useMemo(
    () => ({
      en: [
        { value: "Honor", name: "Honor", price: 49.99, delivery_time: "1-5 bussiness days" },
        // { value: "Huawei", name: "Huawei", price: 44.99, delivery_time: "1-3 bussiness days" },
        { value: "Motorola", name: "Motorola", price: 59.99, delivery_time: "1-3 bussiness days" },
        { value: "Samsung", name: "Samsung", price: 30.00, delivery_time: "1-5 bussiness hours" },
        // { value: "Samsung service 2", name: "Samsung service 2", price: 49.99, delivery_time: "1-3 bussiness days" },
        { value: "Xiaomi (Mi Account)", name: "Xiaomi (Mi Account)", price: 44.99, delivery_time: "1-5 bussiness days" },
      ],
      es: [
        { value: "Honor", name: "Honor", price: 49.99, delivery_time: "1-5 bussiness days" },
        // { value: "Huawei", name: "Huawei", price: 44.99, delivery_time: "1-3 bussiness days" },
        { value: "Motorola", name: "Motorola", price: 59.99, delivery_time: "1-3 bussiness days" },
        { value: "Samsung", name: "Samsung", price: 30.00, delivery_time: "1-5 bussiness hours" },
        // { value: "Samsung service 2", name: "Samsung service 2", price: 49.99, delivery_time: "1-3 bussiness days" },
        { value: "Xiaomi (Cuenta Mi)", name: "Xiaomi (Cuenta Mi)", price: 44.99, delivery_time: "1-5 bussiness days" },
      ],
    }),
    []
  );
  // Compute carrierData based on currentLanguageCode
  const manufacturerData = localizedManufacturerData[currentLanguageCode];
  const navigate = useNavigate();
  const [state, setState] = useState({
    productData: [],
    formValue: null,
    validToken: [],
  });
  const { startLoading, stopLoading } = useLoading();
  const captchaRef = useRef(null);
  // State to force re-rendering of the ReCAPTCHA component
  const [captchaKey, setCaptchaKey] = useState(Date.now()); // Use timestamp as a key for re-rendering
  // Reload captcha when language changes
  useEffect(() => {
    const handleLanguageChange = () => {
      const newLanguageCode = cookies.get("i18next") || "en";

      if (newLanguageCode === "en") { setYoutubeUrls('https://youtu.be/45b6BZyg23w') } else {
        setYoutubeUrls('https://youtu.be/QN-rOHt0b5M')

      }
      if (newLanguageCode !== currentLanguageCode) {
        setCurrentLanguageCode(newLanguageCode);
        // Change the key to remount ReCAPTCHA
        setCaptchaKey(Date.now());
      }
    };
    // Listen for cookie changes or language changes (you could also use another event)
    const intervalId = setInterval(handleLanguageChange, 1000); // Polling for language changes (or use other detection)
    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [currentLanguageCode]);
  const whyChooseList = [
    { title: "whyush1", text: "whyusp1" },
    { title: "whyush2", text: "whyusp2" },
    { title: "whyush3", text: "whyusp3" },
    { title: "whyush4", text: "whyusp4" },
    { title: "whyush5", text: "whyusp5" },
    { title: "whyush6", text: "whyusp6" }
  ];
  useEffect(() => {
    setYoutubeUrls('https://youtu.be/45b6BZyg23w')
    const fetchProductData = async () => {
      try {
        const res = await getCustomProductById(4);
        setState((prevState) => ({ ...prevState, productData: res }));
      } catch (error) {
        toast.error("Error fetching product data.");
      } finally {
      }
    };
    fetchProductData();
  }, []);

  const validationSchema = Yup.object({
    imei: Yup.string()
      .required(t("ErrorField4"))
      .length(15, t("ErrorField41")),
    email: Yup.string().email(t("ErrorField51")).required(t("ErrorField5")),
    manufacturer: Yup.string().required(t("ErrorField2")),
  });

  // Verify reCAPTCHA token
  const verifyToken = useCallback(async (token) => {
    try {
      const response = await httpRequest.post(`${process.env.REACT_APP_API_URL}/verifyToken`, {
        reCAPTCHA_TOKEN: token,
      });
      toast.success(t("IEMIS1"));

      return [response];
    } catch (error) {
      console.error("Error verifying token:", error);
      return [];
    }
  }, [t]);

  const handleSubmit = useCallback(
    async (values, { resetForm }) => {
      try {
        const token = captchaRef.current.getValue();
        captchaRef.current.reset();

        if (!token) {
          return toast.error(t("Sorry!! Verify you're not a bot"));
        }

        startLoading();
        const validTokenResponse = await verifyToken(token);
        if (!validTokenResponse[0]?.success) {
          throw new Error("Verification failed");
        }

        const updatedValues = {
          ...values,
          carrier: "Global",
          model: "FRP/Google Account Removal",
          phone: "",
        };

        setState((prevState) => ({
          ...prevState,
          formValue: updatedValues,
          validToken: validTokenResponse,
        }));

        navigate("/review-order", { state: { formValue: updatedValues, productData: state.productData } });
        localStorage.setItem("state", JSON.stringify({ formValue: updatedValues, productData: state.productData }));
      } catch (error) {
        toast.error("Something went wrong.");
      } finally {
        stopLoading();
      }
    },
    [navigate, state.productData, t, startLoading, stopLoading]
  );

  const handleManufacturerChange = async (selectedOption, handleChange) => {
    try {
      console.log("Selected Option:", selectedOption);

      // Extracting manufacturer details correctly
      const { value, name, price, delivery_time } = selectedOption.value;

      // Update the manufacturer field in Formik
      handleChange("manufacturer")(name);

      // Ensure productData is not empty before updating
      setState(prevState => ({
        ...prevState,
        productData: prevState.productData.length > 0
          ? prevState.productData.map(product => ({
            ...product,
            price: price, // Corrected access
            delivery_time: delivery_time // Corrected access
          }))
          : [{ price, delivery_time }] // Default product if empty
      }));

    } catch (error) {
      console.error("Error in handleManufacturerChange:", error);
    } finally {
      console.log("Updated Product Data:", state.productData);
    }
  };


  return (
    <div>
      <section className="banner-ImeiBlacklist py-md-2 pt-md-5 pt-0 pb-0 position-relative">
        <div className="container py-2 pt-md-4 pb-md-5">
          <div className="row justify-content-center p-3 p-md-0">
            <div className="col-md-6 col-12 px-md-5 p-0">
              <h1 className="fw-semibold display-5 mt-md-5">
                {t('SAR1')}
              </h1>
              <p className=" imeiblacklist2 m-0 my-3">
                {t('SAR2')}
              </p>
              <p className=" imeiblacklist2 m-0">
                {t('SAR3')}
              </p>
            </div>
            <div className="col-12 col-md-6 mt-md-0 mt-3 p-0">
              <div className="px-md-4">
                <>
                  <Formik
                    initialValues={{ imei: "", email: "", manufacturer: "" }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ handleChange, values, handleBlur }) => (
                      <Form className="bg-white rounded p-4 form-border">
                        <div className="card unlock-card bg-transparent border-0 my-md-0 my-1">
                          <div className="card-body">
                            <h5 className="fw-semibold mb-4">{t("SARh111")}</h5>
                            <div>
                              <p className="mb-0 fw-semibold mb-2">{t("SARh2")}</p>
                              <div className="input-group border d-flex align-items-center p-2 rounded mb-3 bg-white w-100">
                                <label className="input-group-text bg-transparent border-0" htmlFor="imei">
                                  <img loading="lazy" src="/images/ph_phone-call (1).svg" alt="" />
                                  <span className="mx-2mx-0 text-secondary"> | </span>
                                </label>
                                <Field
                                  type="text"
                                  className="form-control border-0 py-1"
                                  placeholder={t("FormField4")}
                                  aria-label={t("FormField4")}
                                  id="imei"
                                  name="imei"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <ErrorMessage name="imei" component="div" className="error-message ps-5 ms-4" />
                            <div>
                              <p className="mb-0 fw-semibold">Email</p>
                              <div className="input-group border d-flex align-items-center p-2 rounded mb-3 bg-white w-100">
                                <label className="input-group-text bg-transparent border-0" htmlFor="email">
                                  <img loading="lazy" src="/images/at-sign2.svg" alt="" />
                                  <span className="mx-2mx-0 text-secondary"> | </span>
                                </label>
                                <Field
                                  type="email"
                                  className="form-control border-0 py-1"
                                  placeholder={t("FormField5")}
                                  aria-label={t("FormField5")}
                                  id="email"
                                  name="email"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <ErrorMessage name="email" component="div" className="error-message ps-5 ms-4" />
                            <div>
                              <h6 className="fw-semibold small-font">{t("Formlabel2")}</h6>
                              <div className="input-group mb-3 border rounded-input d-flex justify-content-between">
                                <label
                                  className="input-group-text border-0 bg-transparent"
                                  htmlFor="carrier"
                                >
                                  <img
                                    loading="lazy"
                                    src="/images/manufacturer.svg"
                                    alt=""
                                    width={'20'}
                                  />
                                  <span class="mx-2mx-0 text-secondary"> | </span>
                                </label>
                                <Select
                                  className="form-select-carrier border-0"
                                  id="manufacturer"
                                  name="manufacturer"
                                  options={manufacturerData?.map((manufacturer) => ({
                                    value: manufacturer,
                                    label: manufacturer.name,
                                  }))}
                                  value={manufacturerData?.find(
                                    (option) => option.value.name === values.manufacturer
                                  )}
                                  onChange={(selectedOption) => {
                                    handleManufacturerChange(
                                      selectedOption,
                                      handleChange
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  isSearchable={true}
                                  filterOption={(option, inputValue) => {
                                    const optionLabel = option.label.toLowerCase();
                                    const searchValue = inputValue.toLowerCase();
                                    return optionLabel.includes(searchValue);
                                  }}
                                  placeholder={t("FormField2")}
                                />
                              </div>
                            </div>
                            <ErrorMessage name="manufacturer" component="div" className="error-message ps-5 ms-4" />

                            <button type="submit" className="btn btn-lg mt-md-0 mt-2 w-100 unlock-btn">
                              {t("Button3")}
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <div>
                    <ReCAPTCHA
                      key={captchaKey}
                      className="recaptcha"
                      sitekey={"6LeLE9YqAAAAADOc0-cFTfmPBQRxgXbIGjXi-FQH"}
                      ref={captchaRef}
                      hl={currentLanguageCode}
                    />
                  </div>
                </>
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GoogleFRPSection />
      <WhyChooseUs
        title={t("whyush")}
        bgClass="bg-class"
        whyChooseList={[
          { title: "whyush1", text: "whyusp1" },
          { title: "whyush2", text: "whyusp2" },
          // { title: "whyush3", text: "whyusp3" },
          { title: "whyush4", text: "whyusp4" },
          { title: "whyush5", text: "whyusp5" },
          { title: "whyush6", text: "whyusp6" }
        ]}
      />
      {/* {youtubeUrls && <ServicePromotionVideos youtubeUrls={[youtubeUrls]} title={t('SAR1')} />} */}

    </div>
  );
};

export default SamsungAccRemoval;
