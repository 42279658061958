import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { LoadingProvider } from './_shared/LoadingContext';
import "./i18n";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"; // Import service worker registration
console.log(process.env.PUBLIC_URL)
// Disable React Developer Tools in production
if (process.env.NODE_ENV === "production") {
  // Disable React DevTools in production
  const noop = () => {};
  Object.defineProperty(window, "__REACT_DEVTOOLS_GLOBAL_HOOK__", {
    get: () => ({
      supportsFiber: true,
      inject: noop,
      onCommitFiberRoot: noop,
      onCommitFiberUnmount: noop,
    }),
  });

  // Disable right-click context menu
  document.addEventListener("contextmenu", (e) => e.preventDefault());

  // Block inspect element shortcuts (Desktop only)
  document.addEventListener("keydown", (e) => {
    if (
      e.ctrlKey &&
      (e.key === "u" || e.key === "Shift" || e.key === "i" || e.key === "j" || e.key === "c")
    ) {
      e.preventDefault();
    }

    if (e.key === "F12") {
      e.preventDefault();
    }
  });

  // Detect developer tools with refined logic
  const detectDevTools = () => {
    // Check if the user is on a mobile device
    const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    if (isMobile) {
      return; // Skip checks for mobile devices
    }

    const threshold = 160; // Threshold for detecting dev tools
    const widthThreshold = window.outerWidth - window.innerWidth > threshold;
    const heightThreshold = window.outerHeight - window.innerHeight > threshold;

    if (widthThreshold || heightThreshold) {
      alert("Developer tools are open. Please close them to continue.");
      // Optional: Instead of reloading, disable certain features
      document.body.innerHTML =
        "<h1>Access blocked</h1><p>Please close developer tools to access this page.</p>";
    }
  };

  setInterval(detectDevTools, 2000); // Check every 2 seconds
}



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <LoadingProvider>
        <App />
      </LoadingProvider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Register the service worker
// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     if (window.confirm("New version available. Do you want to update?")) {
//       if (registration.waiting) {
//         registration.waiting.postMessage({ type: "SKIP_WAITING" });
//         registration.waiting.addEventListener("statechange", (e) => {
//           if (e.target.state === "activated") {
//             window.location.reload();
//           }
//         });
//       }
//     }
//   },
// });