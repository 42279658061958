import React, { useState, useEffect } from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { blue, pink, red } from "@mui/material/colors";
import Zendesk, { ZendeskAPI } from "../../ZendexConfig";
import { light } from "@mui/material/styles/createPalette";
const ZENDESK_KEY = "295488c9-daa1-4f63-971d-196fe22df84f";

const Footer = ({ onComponent1ButtonClick }) => {
  const { t } = useTranslation();
  const [openChat, setOpenChat] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleLoaded = () => {
    if (openChat) {
      ZendeskAPI("messenger", "open");
      setOpenChat(!openChat);
    } else {
      ZendeskAPI("messenger", "close");
      setOpenChat(!openChat);
    }
  };
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [installable, setInstallable] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e); // Save the event for later
      setInstallable(true); // Show the install button
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the install prompt
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === "accepted") {
        console.log("PWA installation accepted");
      } else {
        console.log("PWA installation dismissed");
      }
      setDeferredPrompt(null); // Clear the saved event
      setInstallable(false); // Hide the button
    }
  };

  return (
    <div>
      <footer>
        <div className="container py-md-5">
          <div className="row py-md-4 py-3 border-bottom">
            <div className="col-md-6 col-lg-3 px-3 px-md-0 pe-md-3">
              <img loading="lazy" src="/images/unlock-logo.png" alt="" />
              <p className="my-4 me-5 me-md-3 small word-spacing-5">{t("FooP1")}</p>
              <div className="d-flex gap-3 social-icon pb-3">
                <Link
                  to={"https://www.facebook.com/unlockriver"}
                  className="rounded-circle"
                >
                  <FacebookIcon sx={{ color: blue[500] }} />
                </Link>
                <Link
                  to={"https://www.instagram.com/unlockriver"}
                  className="rounded-circle"
                >
                  <InstagramIcon sx={{ color: pink[500] }} />
                </Link>

                <Link
                  className="rounded-circle "
                  to={
                    "https://www.youtube.com/channel/UCgFpJKyIywUc8arDxhABGPw"
                  }
                >
                  <YouTubeIcon sx={{ color: red[500] }} />
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 px-3 px-md-4">
              <h4 className="fw-semibold">{t("FooH1")}</h4>
              <ul className="menu-link pt-3">
                <li>
                  <Link to={"unlock-phone"} onClick={scrollToTop}>
                    {t("FooH11")}{" "}
                  </Link>
                </li>
                <li>
                  <Link to={"/premium-blacklist-checker"} onClick={scrollToTop}>
                    {t("FooH12")}
                  </Link>
                </li>
                <li>
                  <Link
                    to={"iphone-fmi-check"}
                    onClick={scrollToTop}
                  >
                    {t("FooH13")}
                  </Link>
                </li>
                <li>
                  <Link
                    to={"google-frp-removal"}
                    onClick={scrollToTop}
                  >
                    {t("Headerlink4")}
                  </Link>
                </li>
                <li>
                  <Link to="https://www.jdoqocy.com/click-101030372-13828285?url=https%3A%2F%2Fwww.tenorshare.com%2Fproducts%2F4ukey-unlocker.html&cjsku=1016" target="_blank" rel="noopener noreferrer" onClick={scrollToTop}>
                    {t("FooH15")}
                  </Link>
                </li>
                <li>
                  <Link
                    to={"icloud-unlock"}
                    onClick={scrollToTop}
                  >
                    {t("Headerlink6")}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-3 px-3 px-md-4">
              <h4 className="fw-semibold">{t("FooH2")}</h4>
              <ul className="menu-link pt-3">
                <li>
                  <Link to={"blogs"} onClick={scrollToTop}>{t("FooH21")}</Link>
                </li>
                <li>
                  <Link to={"about-us"} onClick={scrollToTop}>{t("FooH22")}</Link>
                </li>
                <li>
                  <Link to={"terms-and-conditions"} onClick={scrollToTop}>
                    {t("FooH23")}
                  </Link>
                </li>
                <li>
                  <Link to={"privacy-policy"} onClick={scrollToTop}>
                    {t("FooH24")}
                  </Link>
                </li>
                <li>
                  <Link to={"refund-policy"} onClick={scrollToTop}>
                    {t("FooH25")}
                  </Link>
                </li>
                <li>
                  <Link to={"delivery-policy"} onClick={scrollToTop}>
                    {t("FooH26")}
                  </Link>
                </li>
                <li>
                  <Link to={"request-a-refund"} onClick={scrollToTop}>
                    {t("FooH27")}
                  </Link>
                </li>
                <li>
                  <Link to={"promotions"} onClick={scrollToTop}>
                    {t("FooH28")}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-3 px-3 px-md-4">
              <h4 className="fw-semibold">{t("FooH3")}</h4>
              <ul className="menu-link pt-3">
                <li>
                  <Link to={"faq"} onClick={scrollToTop}>
                    {t("FooH31")}
                  </Link>
                </li>
                <li>
                  <Link to={"track-order"} onClick={scrollToTop}>
                    {t("FooH32")}
                  </Link>
                </li>
                {/* <li>
                  <Link to={"code-entry-instruction"} onClick={scrollToTop}>
                    {t("FooH33")}
                  </Link>
                </li> */}
                <li>
                  <Link to={"troubleshoot"} onClick={scrollToTop}>
                    {t("FooH34")}
                  </Link>
                </li>
                <li>
                  <Link to={"affiliate-reseller"} onClick={scrollToTop}>
                    {t("FooH35")}
                  </Link>
                </li>
                <li>
                  <Link to={"testimonial"} onClick={scrollToTop}>
                    {t("FooH36")}
                  </Link>
                </li>
                {installable && (
                  <li>
                    <Link onClick={handleInstallClick}>
                      Install App
                    </Link>
                  </li>
                )}
              </ul>
            </div>
            {/* <div>
              <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={handleLoaded} />
            </div> */}

          </div>
          <div className="footer-bottom copyright row d-block d-md-flex justify-content-between pt-3">
            <div className="col-12 col-md-8 px-0">
              <Link
                to={"https://www.youtube.com/channel/UCgFpJKyIywUc8arDxhABGPw"}
              >
                <p className="copyright-text">
                  ©2024. <strong>Unlockriver.com</strong> {t("FooP2")}
                </p>
              </Link>
            </div>
            <div className="col-12 col-md-4">

              <div className="d-flex justify-content-center ">
                <Link to={"privacy-policy"} onClick={scrollToTop}>
                  <p>{t("FooP3")}</p>
                </Link>
                <span className="mx-3 d-block">|</span>
                <Link to={"terms-and-conditions"} onClick={scrollToTop}>
                  <p>{t("FooP4")}</p>
                </Link>
              </div>

            </div>
          </div>
        </div>
      </footer>

      <a target="_blank" id="whatsapp_icon" href="https://api.whatsapp.com/send?phone=50230043174&amp;text=">
        <WhatsAppIcon style={{ color: 'white', fontSize: '30px' }} />
      </a>
    </div>
  );
};

export default Footer;
