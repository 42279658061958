import React,{forwardRef} from 'react'
import "./frpHowitWork.css";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
const FrpHowitWork =  forwardRef((props, ref)=> {
  const { t } = useTranslation();
  const location = useLocation();
  let content;
  console.log(location.pathname)
  if (location.pathname === "/google-frp-removal") {
    content = t('Howitworkp31');
  } else if (location.pathname === "/icloud-unlock") {
    content = t('Howitworkp32');
  } else {
    content = t('Howitworkp30');
  }
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div ref={ref}>
        <section className="frp-how-it-works py-5">
            <div className="container">
                <div className="row frphowitwrk-col py-md-5 rounded-5 mx-md-5 px-md-4 justify-content-center">
                    <div className="col-12 col-md-12 px-md-2">
                     <h2 className='text-center display-6 fw-semibold mb-2 py-3 py-md-1'>{t("HiwHeader")}</h2> 
                     <p className='small-font text-center pb-md-5'>{t("Howitwork1111")}</p>
                    </div>
                    <div className="col-12 col-md-3 col-lg-4  px-3">
                      <div className="pb-md-4">
                       
                        <img loading="lazy" className='' src="/images/submit-device.svg" alt="" height="120" width="120" />
                      </div>
                      <div className='py-1 ps-md-2'>
                        
                          <span className='frphowtowork-numbering'>{t("Howitworkstep1")}</span>
                       
                        <h3 className="fw-semibold fs-5 py-3">{t("FrpHowitworkh1")}</h3>
                        <p className='small-font'>{t("FrpHowitworkp1")} <Link className='text-black fw-semibold' to={'/premium-blacklist-checker'} onClick={scrollToTop}>{t("FrpHowitworkp11")}</Link> {t("FrpHowitworkp12")}</p>
                      </div>
                      
                    </div>
                    
                    <div className="col-12 col-md-3 col-lg-4 px-3">
                      <div className="pb-md-4">
                       
                        <img loading="lazy" className='' src="/images/submit-device2.svg" alt="" height="120" width="120" />
                      </div>
                      <div className='py-1 ps-md-2'>
                          <span className='frphowtowork-numbering'>{t("Howitworkstep2")}</span>
                       
                        <h3 className="fw-semibold fs-5 py-3">{t("FrpHowitworkh2")}</h3>
                        <p className='small-font'>{t("FrpHowitworkp2")}</p>
                        
                      </div>
                    </div>
                    <div className="col-12 col-md-3 col-lg-4 px-3">
                      <div className="pb-md-4">
                       
                        <img loading="lazy" className='' src="/images/submit-device3.svg" alt="" height="120" width="120" />
                      </div>
                      <div className='py-1 ps-md-2'>
                          <span className='frphowtowork-numbering'>{t("Howitworkstep3")}</span>
                        <h3 className="fw-semibold fs-5 py-3">{t("FrpHowitworkh3")}</h3>
                        <p className="small-font">{t("FrpHowitworkp3")} </p>
                      </div>
                    </div>
                    
                </div>
            </div>
        </section>
    </div>
  )
})

export default FrpHowitWork;