import React, { useState } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useLoading } from "../../_shared/LoadingContext";
import "./CheckoutForm.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { purple } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TermsandConditions from "../../shared/T&C/T&c";
import httpService from '../../_services/httpServices'
const style = {
  position: 'absolute',
  top: '3%',
  left: '3%',
  width: '50%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  height: '94%',
  'overflow-x': 'hidden',
  'overflow-y': 'scroll',
};

const CheckoutForm = ({ clientSecret, formValue, customer }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { t } = useTranslation();
  const { startLoading, stopLoading } = useLoading();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    startLoading();

    if (!stripe || !elements || !isNameValid()) {
      stopLoading();
      return;
    }
    try {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: name,
              email: formValue.email,
            },
          },
        },
        redirect: "if_required",
      });
      console.log("Ress", result)
      handlePaymentResult(result);
    } catch (error) {
      console.log(error)
      handleError(error);
    } finally {
      stopLoading();
    }
  };

  const handlePaymentResult = (result) => {
    if (result.error) {
      console.error(result.error.message);
      toast.error(result.error.message);
      handleUnSuccessfulPayment(result);
    } else {
      handleSuccessfulPayment(result);
    }
  };

  const handleSuccessfulPayment = async (result) => {
    try {
      const addOrderResponse = await httpService.post("/order/add", {
        orderId: result.paymentIntent.id,
        clientSecret: clientSecret,
        status: "succeeded",
      });
  
      if (addOrderResponse.orderId.status === "succeeded") {
        toast.success(addOrderResponse.message);
  
        // Store order details in localStorage before navigating
        localStorage.setItem("orderDetails", JSON.stringify({ order: addOrderResponse.orderId }));
        console.log("addOrderResponse.orderId: ", addOrderResponse.orderId);
        localStorage.setItem("order", JSON.stringify({ order: addOrderResponse?.orderId }));
  
        navigate("/success");
      } else {
        // Handle unexpected status
        console.error("Unexpected order status:", addOrderResponse.orderId.status);
      }
    } catch (error) {
      console.error("Error handling successful payment:", error);
      toast.error("Failed to add order. Please try again.");
    }
  };
  
  const handleUnSuccessfulPayment = async (result) => {
    try {
      const addOrderResponse = await httpService.post("/order/add", {
        orderId: clientSecret,
        clientSecret: clientSecret,
        status: "Failed",
      });
  
      if (addOrderResponse.orderId.status === "succeeded") {
        toast.success(addOrderResponse.message);
        console.log("addOrderResponse: ", addOrderResponse);
        localStorage.setItem("order", JSON.stringify({ order: addOrderResponse?.orderId }));
        navigate("/success");
      } else {
        // Handle unexpected status
        console.error("Unexpected order status:", addOrderResponse.orderId.status);
      }
    } catch (error) {
      console.error("Error handling unsuccessful payment:", error);
      toast.error("Failed to add order. Please try again.");
    }
  };

  const [agree, setAgree] = useState(false);

  const checkboxHandler = () => {
    setAgree(!agree);
  };

  const handleError = (error) => {
    console.error("Error in handleSubmit:", error);
    toast.error("An error occurred during payment. Please try again.");
  };

  const isNameValid = () => {
    if (name.trim() === "") {
      setNameError(t("ContE1"));
      return false;
    } else {
      setNameError("");
      return true;
    }
  };

  return (
    <>
      <form>
        <div>
          <input
            type="text"
            className="w-100 p-3 border rounded"
            placeholder={t("UnpF1")}
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {nameError && <div className="error">{nameError}</div>}
        </div>
        <PaymentElement />
        <button
          type="submit"
          className="btn text-white border-none w-100 rounded px-5 py-2 mt-3 cus-payment-btn"
          style={{ background: "#6A5696" }}
          disabled={agree}
          onClick={handleSubmit}
        >
          {t("UnpCP3")}
        </button>
        {/* <div className="d-flex tremcondition">
          <Checkbox
            id="agree"
            checked={agree[0] && agree[1]}
            indeterminate={agree[0] !== agree[1]}
            onChange={checkboxHandler}
            sx={{
              color: purple[900],
              "&.Mui-checked": {
                color: purple[900],
              },
            }}
          />
          <label htmlFor="agree" className="border-0 ms-3">
            <h6 className="fw-normal lh-base pt-3">
              <span className="text-secondary">{t("UnpTC1")}</span>{" "}
              <Link className="text-dark" onClick={handleOpen}>{t("UnpTC2")}</Link>
            </h6>
          </label>
        </div> */}
        <ToastContainer />
      </form>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <TermsandConditions col={10} />
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default CheckoutForm;
