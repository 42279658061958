import './App.css';
import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import AppRouter from './AppRouter';
import Header from './components/header/Header';
import UsefulTools from './shared/usefulTools/usefulTools';
import Testimonial from './shared/testimonial/testimonial';
import Faq from './shared/faq/faq';
import ContactUs from './shared/contactUs/contactUs';
import NewContactUs from './shared/contactUs/NewContactUs';
import HowitWork from './shared/howitWork/howitWork';
import FrpHowitWork from './shared/howitWork/FrpHowitWork';
import Footer from './components/footer/Footer';
import Spinner from './_shared/spinner';
import { LanguageProvider } from './_shared/LanguageContext';
import Videos, { PromotionVideos } from './shared/videos/PromotionVideos';
import ServicePromotionVideos from "./shared/videos/ServicePromotionVideos";
import cookies from "js-cookie";

// const Header= lazy(() => import("./components/header/Header"))
// const UsefulTools= lazy(() => import("./shared/usefulTools/usefulTools"));
// const Testimonial= lazy(() => import("./shared/testimonial/testimonial"));
// const Faq= lazy(() => import("./shared/faq/faq"));
// const ContactUs= lazy(() => import("./shared/contactUs/contactUs"));
// const Videos= lazy(() => import("./shared/videos/Videos"));
// const HowitWork= lazy(() => import("./shared/howitWork/howitWork"));
// const Footer= lazy(() => import("./components/footer/Footer"));

function App() {
  const location = useLocation();
  const { t } = useTranslation();
  const [youtubeUrls, setYoutubeUrls] = useState(null);
  const [currentLanguageCode, setCurrentLanguageCode] = useState(cookies.get("i18next") || "en");

  useEffect(() => {
    document.title = t('webTitle') // Set the page title when the component mounts or when location changes
  }, [location.pathname, t]);
  const componentRefs = {
    1: useRef(null),
    2: useRef(null),
    3: useRef(null),
    4: useRef(null),
  };
  useEffect(() => {
    const handleLanguageChange = () => {
      const newLanguageCode = cookies.get("i18next") || "en";

      if (location.pathname == '/icloud-unlock') {
        if (newLanguageCode === "en") { setYoutubeUrls('https://www.youtube.com/watch?v=jmqGYGQb2ag') } else {
          setYoutubeUrls('https://www.youtube.com/watch?v=tMGTMxOE_cU')

        }
      } else {
        if (newLanguageCode === "en") { setYoutubeUrls('https://youtu.be/45b6BZyg23w') } else {
          setYoutubeUrls('https://youtu.be/QN-rOHt0b5M')

        }
      }
    };
    const intervalId = setInterval(handleLanguageChange, 1000); // Polling for language changes (or use other detection)
    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [currentLanguageCode,location.pathname]);

  const scrollToComponent = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const componentsToRender = [
    {
      component: <UsefulTools />,
      condition: [
        '/unlock-phone',
        '/iphone-fmi-check',
        '/icloud-unlock',
        '/imei-blacklist-checker',
        '/icloud-activation-lock-checker',
        '/original-network-identifier',
        "premium-blacklist-checker",
        "basic-imei-checker",
        "simple-imei-checker",
        "apple-full-info-check-gsx",
        "apple-warranty-check",
        "esim-info-check",
        "carrier-check-by-phone-number",
        "samsung-info-check",
        "samsung-info-check-knox-status",
        "sony-info-check",
        "xiaomi-info-check",
        "google-pixel-info-check"
      ].some(path => location.pathname.includes(path))
      
    },
    {
      component: <HowitWork ref={componentRefs[2]} />,
      condition: ['/unlock-phone', '/icloud-unlock', '/imei-blacklist-checker', '/icloud-activation-lock-checker', '/original-network-identifier'].some(path => location.pathname.includes(path))
      // || location.pathname.includes('/checkService')
    },
    {
      component: <FrpHowitWork ref={componentRefs[2]} />,
      condition: ['/google-frp-removal'].some(path => location.pathname.includes(path))
      // || location.pathname.includes('/checkService')
    },
    // Uncomment and update if necessary
    { component: <Videos />, condition: ['/unlock-phone'].some(path => location.pathname.includes(path)) },
    { component: <ServicePromotionVideos youtubeUrls={[youtubeUrls]} title={t('VideoTitle')} />, condition: ['/google-frp-removal','/icloud-unlock'].some(path => location.pathname.includes(path)) && youtubeUrls },
    {
      component: <Testimonial ref={componentRefs[3]} />,
      condition: [
        '/unlock-phone',
        '/google-frp-removal',
        '/iphone-fmi-check',
        '/icloud-unlock',
        '/imei-blacklist-checker',
        '/icloud-activation-lock-checker',
        '/original-network-identifier',
        "premium-blacklist-checker",
        "basic-imei-checker",
        "simple-imei-checker",
        "apple-full-info-check-gsx",
        "apple-warranty-check",
        "esim-info-check",
        "carrier-check-by-phone-number",
        "samsung-info-check",
        "samsung-info-check-knox-status",
        "sony-info-check",
        "xiaomi-info-check",
        "google-pixel-info-check"
      ].some(path => location.pathname.includes(path))
    },
    {
      component: <Faq ref={componentRefs[1]} />,
      condition: [
        '/unlock-phone',
        '/google-frp-removal',
        '/iphone-fmi-check',
        '/icloud-unlock',
        '/imei-blacklist-checker',
        '/icloud-activation-lock-checker',
        '/original-network-identifier',
        "premium-blacklist-checker",
        "basic-imei-checker",
        "simple-imei-checker",
        "apple-full-info-check-gsx",
        "apple-warranty-check",
        "esim-info-check",
        "carrier-check-by-phone-number",
        "samsung-info-check",
        "samsung-info-check-knox-status",
        "sony-info-check",
        "xiaomi-info-check",
        "google-pixel-info-check"
      ].some(path => location.pathname.includes(path))
    }
    
  ];

  return (
    <div>
      <LanguageProvider>
        <Spinner />
        {/* <Suspense fallback={<div >Loading...</div>}> */}
        <Header
          onComponent1ButtonClick={() => scrollToComponent(componentRefs[1])}
          onComponent2ButtonClick={() => scrollToComponent(componentRefs[2])}
          onComponent3ButtonClick={() => scrollToComponent(componentRefs[3])}
          onComponent4ButtonClick={() => scrollToComponent(componentRefs[4])}
        />
        <AppRouter />
        {componentsToRender.map(({ component, condition }, index) => (
          condition && <React.Fragment key={index}>{component}</React.Fragment>
        ))}
        {!['/contact-us', '/google-frp-removal'].includes(location.pathname) && (
          <ContactUs ref={componentRefs[4]} />
        )}
        {['/google-frp-removal'].includes(location.pathname) && (
          <NewContactUs ref={componentRefs[4]} />
        )}
        <Footer onComponent1ButtonClick={() => scrollToComponent(componentRefs[1])} />
        {/* </Suspense> */}
      </LanguageProvider>
    </div>

  )
}

export default App;
