import React, { useContext, useState, useEffect } from "react";
import classNames from "classnames";
import "./header.css";
import "react-phone-number-input/style.css";
import { Link, useLocation } from "react-router-dom";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../_shared/LanguageContext";
import { getVisitorIP } from "../../_services/unlockPhone";
const Header = ({
  onComponent1ButtonClick,
  onComponent2ButtonClick,
  onComponent3ButtonClick,
  onComponent4ButtonClick,
}) => {
  const [activeItems, setActiveItems] = useState(Array(5).fill(false));
  const { handleLanguageChange } = useContext(LanguageContext);
  const currentLanguageCode = cookies.get("i18next") || "en";
  const additionalClass = currentLanguageCode === "en" ? "x" : "";
  const additionalClass1 = currentLanguageCode === "es" ? "x" : "";
  const { t } = useTranslation();
  const [isHidden, setIsHidden] = useState(false);

  const toggleVisibility = () => {
    setIsHidden(!isHidden);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const navItems = [
    { path: "/unlock-phone", label: t("FooH11") },
    { path: "/imei-blacklist-checker", label: t("FooH12") },
    {
      path: "/icloud-activation-lock-checker",
      label: t("FooH13"),
    },
    {
      path: "/original-network-identifier",
      label: t("FooH14"),
    },
    {
      path: "https://www.jdoqocy.com/click-101030372-13828285?url=https%3A%2F%2Fwww.tenorshare.com%2Fproducts%2F4ukey-unlocker.html&cjsku=1016",
      label: t("FooH15"),
    },
  ];
  const location = useLocation();
  const getItemClassName = (path) => {
    return classNames("dropdown-item py-2", {
      active: location.pathname === path,
    });
  };
  useEffect(() => {

    getVisitorIP().then((res) => {
      console.log(res);
      if (res.country_code === "ES") {
        handleLanguageChange("es");
      } else {
        handleLanguageChange("en");
      }
    });
  }, []);
  const isMatched = [
    "/unlock-phone",
    "/imei-blacklist-checker",
    "/icloud-activation-lock-checker",
    "/original-network-identifier",
    "/about-us",
    "/success",
    "/how-it-works",
    "/testimonial",
    "/blogs",
    "/track-order",
    "/contact-us",
    "/imei-status",
    "/pages-error404"
  ]
  const condition = isMatched.some(path => location.pathname.startsWith(path));

  return (
    <div>
      <section className="top-bar bg-dark text-white">
        <div className="container pt-2 pb-2">
          <div className="row align-items-center">
            <div className="col-8 pt-2 pt-md-0 col-md-5 d-flex align-items-center headermail">
              <i className="fas fa-at"></i> support@unlockriver.com
              <ul className="list-inline mb-0 ms-3">
                <li className="list-inline-item m-0 me-md-3">
                  <Link
                    to={"https://www.facebook.com/unlockriver"}
                    className="btn btn-dark headermail py-0"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                </li>
                <li className="list-inline-item m-0 me-md-3">
                  <Link to={""} className="btn headermail btn-dark py-0">
                    <i className="fab fa-youtube"></i>
                  </Link>
                </li>
                <li className="list-inline-item m-0 me-md-3">
                  <Link
                    to={"https://www.instagram.com/unlockriver/"}
                    className="btn btn-dark headermail py-0"
                  >
                    <i className="fab fa-instagram"></i>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-4 px-0 col-md-2 text-center">
              <Link className="navbar-brand" to="/unlock-phone">
                <img
                  loading="lazy"
                  src="/images/Logo.png"
                  alt="Unlock River"
                  width={'70'}
                />
              </Link>
            </div>
            <div className="col-md-5 text-center d-flex justify-content-end gap-4 align-items-center">
              <div>
                <button
                  type="button"
                  href="#"
                  className={`btn-eng text-white mt-0 px-2 p-1 rounded border-0 ${additionalClass}`}
                  onClick={() => {
                    // window.location.reload();
                    handleLanguageChange("en");
                  }}
                >
                  <img loading="lazy" src="/images/en.svg" alt="English" />
                  English
                </button>
                <span className="mx-2">|</span>
                <button
                  type="button"
                  href="#"
                  className={`btn-eng text-white mt-0 px-2 p-1 rounded border-0 ${additionalClass1}`}
                  onClick={() => {
                    // window.location.reload();
                    handleLanguageChange("es");
                  }}
                >
                  <img loading="lazy" src="/images/es.svg" alt="Spanish" />
                  Spanish
                </button>
              </div>
              <div className="d-flex gap-2 checkorder-status" role="search" >
                <Link to={"/track-order"} onClick={toggleVisibility}>
                  <button className={condition ? 'btn  mt-0 order-status' : 'btn order-status withoutbg'}>
                    {t("Header6")}
                  </button>
                </Link>
                <button
                  className="btn bg cart-btn rounded-circle  mt-0 d-none d-md-none d-lg-block"
                  type="submit"
                >
                  {condition ? <i className="fas fa-shopping-cart text-white"></i> : <img src="/images/cart.svg" alt="cart" />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <header className="bg-transparent position-relative z-1 border-bottom">
        <div className="container-fluid">
          <div className="row">
            <nav className="navbar navbar-expand-lg">
              <div className="container-fluid">
                <Link className="navbar-brand d-block d-md-block d-lg-none" to="/unlock-phone">
                  <img
                    loading="lazy"
                    src="/images/unlock-logo.png"
                    alt="Unlock River"
                    width={'70'}
                  />
                </Link>
                <button
                  className={`${condition ? 'xy' : 'yz'} navbar-toggler  border-0 ${isHidden ? "collapsed" : ""
                    }`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded={isHidden ? "true" : "false"}
                  aria-label="Toggle navigation"
                  onClick={toggleVisibility}
                >
                  <span className={'navbar-toggler-icon'}></span>

                </button>
                <div
                  className={`collapse navbar-collapse ${isHidden ? "show" : ""
                    }`}
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mx-md-auto mb-2 mb-lg-0 gap-3">
                    <li className="nav-item">
                      <Link className="dropdown-item py-2 px-2" onClick={toggleVisibility} to={"/unlock-phone"}><img src="/images/home-icon.png" width={'12'} alt="" ></img> {t('Headerlink1')}</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="dropdown-item py-2 px-2" onClick={toggleVisibility} to={"/premium-blacklist-checker"}><img src="/images/barcode-icon.png" width={'15'} alt="" ></img> {t('Headerlink2')}</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="dropdown-item py-2 px-2" onClick={toggleVisibility} to={'/iphone-fmi-check'}><img className="me-1" src="/images/icon/icloud.png" width={'15'} alt=""></img>{t('Headerlink3')}</Link>
                    </li>

                    <li className="nav-item">
                      <Link className="dropdown-item py-2 px-2" onClick={toggleVisibility}  to={"/google-frp-removal"}><img className="me-1" src="/images/icon/google.png" width={'15'} alt="" ></img> {t('Headerlink4')}</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="dropdown-item py-2 px-2" onClick={toggleVisibility} to="https://www.jdoqocy.com/click-101030372-13828285?url=https%3A%2F%2Fwww.tenorshare.com%2Fproducts%2F4ukey-unlocker.html&cjsku=1016"  target="_blank" rel="noopener noreferrer"><img className="me-1" src="/images/icon/passcode.png" width={'15'} alt=""></img>{t('Headerlink5')}</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="dropdown-item py-2 px-2" to="/testimonial" onClick={toggleVisibility}><img className="me-1" src="/images/tesimonial.png" width={'15'} alt=""></img>{t("FooH36")}</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="dropdown-item py-2 px-2" to="/icloud-unlock" onClick={toggleVisibility}><img className="me-1" src="/images/icloud2-icon.png" width={'15'} alt=""></img>{t('Headerlink6')}</Link>
                    </li>
                    <li className="nav-item dropdown unlock-mega-menu">
                      <Link className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><img className="me-1" src="/images/icon/checkservice.png" width={'15'} alt=""></img> {t('Headerlink7')} </Link>

                      <div className="dropdown-menu mega-menu border-0 border-bottom rounded-0 p-3">
                        <div className="container">
                          <div className="row justify-content-center py-md-2">
                            <div className="col-12 col-md-11 px-0 ">
                              <h4 className="fw-semibold">{t('Apple')}</h4>
                              <div className="d-flex flex-wrap py-2"> 
                                <Link className="mega-menu-link rounded me-md-3 mb-3" onClick={toggleVisibility} to={'/apple-full-info-check-gsx'}>{t('Appleb1')}</Link>
                                <Link className="mega-menu-link rounded me-md-3 mb-3" onClick={toggleVisibility} to={'/simple-imei-checker'}>{t('Androidb1')}</Link>
                                <Link className="mega-menu-link rounded me-md-3 mb-3" onClick={toggleVisibility} to={'/apple-warranty-check'}>{t('Appleb2')}</Link>
                                <Link className="mega-menu-link rounded me-md-3 mb-3" onClick={toggleVisibility} to={'/iphone-fmi-check'}>{t('Appleb3')}</Link>
                                <Link className="mega-menu-link rounded me-md-3 mb-3" onClick={toggleVisibility} to={'/premium-blacklist-checker'}>{t('Appleb4')}</Link>
                                <Link className="mega-menu-link rounded me-md-3 mb-3" onClick={toggleVisibility} to={'/carrier-check-by-phone-number'}>{t('Appleb5')}</Link>
                                <Link className="mega-menu-link rounded me-md-3 mb-3" onClick={toggleVisibility} to={'/esim-info-check'}>{t('Appleb6')}</Link>
                                {/* <Link className="mega-menu-link rounded me-md-3 mb-3" onClick={toggleVisibility} to={'/basic-imei-checker'}>{t('Appleb7')}</Link> */}
                              </div>
                            </div>
                            <div className="col-12 col-md-11 pt-md-2 px-0">
                              <h4 className="fw-semibold">{t('Android')}</h4>
                              <div className="d-flex flex-wrap py-2">
                                <Link className="mega-menu-link rounded me-md-3 mb-3" onClick={toggleVisibility} to={'/simple-imei-checker'}>{t('Androidb1')}</Link>
                                {/* <Link className="mega-menu-link rounded me-md-3 mb-3" onClick={toggleVisibility} to={'/basic-imei-checker'}>{t('Androidb2')}</Link> */}
                                <Link className="mega-menu-link rounded me-md-3 mb-3" onClick={toggleVisibility} to={'/esim-info-check'}>{t('Androidb3')}</Link>
                                <Link className="mega-menu-link rounded me-md-3 mb-3" onClick={toggleVisibility} to={'/samsung-info-check'}>{t('Androidb5')}</Link>
                                <Link className="mega-menu-link rounded me-md-3 mb-3" onClick={toggleVisibility} to={'/carrier-check-by-phone-number'}>{t('Androidb6')}</Link>
                                <Link className="mega-menu-link rounded me-md-3 mb-3" onClick={toggleVisibility} to={'/samsung-info-check-knox-status'}>{t('Androidb7')}</Link>
                                <Link className="mega-menu-link rounded me-md-3 mb-3" onClick={toggleVisibility} to={'/xiaomi-info-check'}>{t('Androidb8')}</Link>
                                <Link className="mega-menu-link rounded me-md-3 mb-3" onClick={toggleVisibility} to={'/google-pixel-info-check'}>{t('Androidb9')}</Link>
                                <Link className="mega-menu-link rounded me-md-3 mb-3" onClick={toggleVisibility} to={"/premium-blacklist-checker"}>{t('Appleb4')}</Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                  </ul>

                  <div className="social-menu row align-items-center d-block d-md-block d-lg-none">
                    <div className="col-md-4 text-center">
                      <button
                        type="button"
                        href="#"
                        className={` mt-1 rounded border-0 ${additionalClass}`}
                        onClick={() => {
                          // window.location.reload();
                          toggleVisibility();
                          handleLanguageChange("en");
                        }}
                      >
                        <img
                          className="mx-1"
                          loading="lazy"
                          src="/images/en.svg"
                          alt="English"
                        />
                        English
                      </button>
                      <span className="mx-0">|</span>
                      <button
                        type="button"
                        href="#"
                        className={` mt-1 rounded border-0 ${additionalClass1}`}
                        onClick={() => {
                          // window.location.reload();
                          toggleVisibility();
                          handleLanguageChange("es");
                        }}
                      >
                        <img
                          className="mx-1"
                          loading="lazy"
                          src="/images/es.svg"
                          alt="Spanish"
                        />
                        Spanish
                      </button>
                    </div>
                    <div className="col-12">
                      <a
                        href="mailto:support@unlockriver.com"
                        className="text-center text-dark"
                        onClick={toggleVisibility}
                      >
                        {" "}
                        <i className="fas fa-at"></i> support@unlockriver.com{" "}
                      </a>
                    </div>
                    <div className="col-12 text-center">
                      <ul className="list-inline mt-2">
                        <li className="list-inline-item m-0 me-md-3">
                          <Link
                            to={"https://www.facebook.com/unlockriver"}
                            className="btn btn-white"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item m-0 me-md-3">
                          <Link to={""} className="btn btn-white">
                            <i className="fab fa-youtube"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item m-0 me-md-3">
                          <Link
                            to={"https://www.instagram.com/unlockriver/"}
                            className="btn btn-white"
                          >
                            <i className="fab fa-instagram"></i>

                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
