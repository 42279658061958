import React, { useState, useRef, useCallback, useEffect, useMemo } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useLoading } from "../../_shared/LoadingContext";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import { getCustomProductById } from "../../_services/unlockPhone";
import "./iCloudUnlock.css";
import httpRequest from '../../_services/httpServices'
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
const SITE_KEY = process.env.REACT_APP_SITE_KEY;
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: 'var(--Neutral-3, #343434)',
    maxWidth: 445,
    fontSize: "16px",
    border: "1px solid #dadde9",
  },
}));
const ICloudUnlock = () => {
  const { t } = useTranslation();
  const [currentLanguageCode, setCurrentLanguageCode] = useState(cookies.get("i18next") || "en");
  const navigate = useNavigate();

  // Define localized carrier data
  const localizedCarrierData = useMemo(
    () => ({
      en: [
        { value: 1, name: "Europe" },
        { value: 2, name: "USA" },
        { value: 3, name: "Worldwide" },
      ],
      es: [
        { value: 1, name: "Europa" },
        { value: 2, name: "Estados Unidos" },
        { value: 3, name: "Resto del mundo" },
      ],
    }),
    []
  );
  // Compute carrierData based on currentLanguageCode
  const carrierData = localizedCarrierData[currentLanguageCode];

  const [state, setState] = useState({
    productData: [],
    formValue: null,
    validToken: [],
    selectedCarrier: [],
    selectedModel: [],
    // carrierData: localizedCarrierData[currentLanguageCode], // Initial carrierData based on language
    modelData: [
      { "value": "iPhone 6", "name": "iPhone 6" },
      { "value": "iPhone 6 Plus", "name": "iPhone 6 Plus" },
      { "value": "iPhone 6s", "name": "iPhone 6s" },
      { "value": "iPhone 6s Plus", "name": "iPhone 6s Plus" },
      { "value": "iPhone 7", "name": "iPhone 7" },
      { "value": "iPhone 7 Plus", "name": "iPhone 7 Plus" },
      { "value": "iPhone 8", "name": "iPhone 8" },
      { "value": "iPhone 8 Plus", "name": "iPhone 8 Plus" },
      { "value": "iPhone X", "name": "iPhone X" },
      { "value": "iPhone XR", "name": "iPhone XR" },
      { "value": "iPhone XS", "name": "iPhone XS" },
      { "value": "iPhone XS Max", "name": "iPhone XS Max" },
      { "value": "iPhone SE (1st Gen)", "name": "iPhone SE (2016)" },
      { "value": "iPhone SE (2nd Gen)", "name": "iPhone SE (2020)" },
      { "value": "iPhone SE (3rd Gen)", "name": "iPhone SE (2022)" },
      { "value": "iPhone 11", "name": "iPhone 11" },
      { "value": "iPhone 11 Pro", "name": "iPhone 11 Pro" },
      { "value": "iPhone 11 Pro Max", "name": "iPhone 11 Pro Max" },
      { "value": "iPhone 12", "name": "iPhone 12" },
      { "value": "iPhone 12 Mini", "name": "iPhone 12 Mini" },
      { "value": "iPhone 12 Pro", "name": "iPhone 12 Pro" },
      { "value": "iPhone 12 Pro Max", "name": "iPhone 12 Pro Max" },
      { "value": "iPhone 13", "name": "iPhone 13" },
      { "value": "iPhone 13 Mini", "name": "iPhone 13 Mini" },
      { "value": "iPhone 13 Pro", "name": "iPhone 13 Pro" },
      { "value": "iPhone 13 Pro Max", "name": "iPhone 13 Pro Max" },
      { "value": "iPhone 14", "name": "iPhone 14" },
      { "value": "iPhone 14 Plus", "name": "iPhone 14 Plus" },
      { "value": "iPhone 14 Pro", "name": "iPhone 14 Pro" },
      { "value": "iPhone 14 Pro Max", "name": "iPhone 14 Pro Max" },
      { "value": "iPhone 15", "name": "iPhone 15" },
      { "value": "iPhone 15 Plus", "name": "iPhone 15 Plus" },
      { "value": "iPhone 15 Pro", "name": "iPhone 15 Pro" },
      { "value": "iPhone 15 Pro Max", "name": "iPhone 15 Pro Max" },
      { "value": "iPhone 16", "name": "iPhone 16" },
      { "value": "iPhone 16 Plus", "name": "iPhone 16 Plus" },
      { "value": "iPhone 16 Pro", "name": "iPhone 16 Pro" },
      { "value": "iPhone 16 Pro Max", "name": "iPhone 16 Pro Max" }
    ]
  });
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const { startLoading, stopLoading } = useLoading();
  const captchaRef = useRef(null);

  // State to force re-rendering of the ReCAPTCHA component
  const [captchaKey, setCaptchaKey] = useState(Date.now()); // Use timestamp as a key for re-rendering
  // Reload captcha when language changes
  useEffect(() => {
    const handleLanguageChange = () => {
      const newLanguageCode = cookies.get("i18next") || "en";
      if (newLanguageCode !== currentLanguageCode) {
        setCurrentLanguageCode(newLanguageCode);
        // Change the key to remount ReCAPTCHA
        setCaptchaKey(Date.now());
      }
    };
    // Listen for cookie changes or language changes (you could also use another event)
    const intervalId = setInterval(handleLanguageChange, 1000); // Polling for language changes (or use other detection)
    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [currentLanguageCode]);
  const validationSchema = Yup.object({
    carrier: Yup.string().required(t("ErrorField1")),

    eSIM: Yup.string()
      .when("carrier", {
        is: "USA", // When carrier is "USA"
        then: (schema) =>
          schema
            .required(t("ErrorField7"))
            .matches(/^\d{19,20}$/, t("ErrorField71")), // eSIM must be 19 or 20 digits
        otherwise: (schema) => schema.notRequired(),
      }),

    model: Yup.string().required(t("ErrorField3")),

    imei: Yup.string()
      .required(t("ErrorField4"))
      .length(15, t("ErrorField41")),

    email: Yup.string().email(t("ErrorField51")).required(t("ErrorField5")),
    confirmation1: Yup.boolean()
      .oneOf([true], t("ErrorField8"))
      .required(),
    confirmation2: Yup.boolean()
      .oneOf([true], t("ErrorField8"))
      .required(),
    confirmation3: Yup.boolean()
      .oneOf([true], t("ErrorField8"))
      .required(),
  });

  const handleCarrierChange = async (selectedOption, handleChange) => {
    try {
      startLoading();
      handleChange("carrier")(selectedOption.value.name);
      const res = await getCustomProductById(selectedOption.value.value);
      setState((prevState) => ({ ...prevState, productData: res }));
    } catch (error) {
      console.error("Error in handleModelChange:", error);
    } finally {
      stopLoading();
    }
  };
  const handleModelChange = async (selectedOption, handleChange) => {
    console.log('selectedOption: ', selectedOption);
    try {

      handleChange("model")(selectedOption.value.name);
    } catch (error) {
      console.error("Error in handleModelChange:", error);
    } finally {
    }
  };
  // Verify reCAPTCHA token
  const verifyToken = useCallback(async (token) => {
    try {
      const response = await httpRequest.post(`${process.env.REACT_APP_API_URL}/verifyToken`, {
        reCAPTCHA_TOKEN: token,
      });
      toast.success(t("IEMIS1"));
      return [response];
    } catch (error) {
      console.error("Error verifying token:", error);
      return [];
    }
  }, [t]);

  const handleSubmit = useCallback(
    async (values, { resetForm }) => {
      try {
        const token = captchaRef.current.getValue();
        captchaRef.current.reset();

        if (!token) {
          return toast.error(t("Sorry!! Verify you're not a bot"));
        }

        startLoading();
        const validTokenResponse = await verifyToken(token);
        if (!validTokenResponse[0]?.success) {
          throw new Error("Verification failed");
        }

        const updatedValues = {
          ...values,
          manufacturer: "Apple",
          phone: "",
        };

        setState((prevState) => ({
          ...prevState,
          formValue: updatedValues,
          validToken: validTokenResponse,
        }));

        navigate("/review-order", { state: { formValue: updatedValues, productData: state.productData } });
        localStorage.setItem("state", JSON.stringify({ formValue: updatedValues, productData: state.productData }));
      } catch (error) {
        toast.error("Something went wrong.");
      } finally {
        stopLoading();
      }
    },
    [navigate, state.productData, t, startLoading, stopLoading]
  );
  return (
    <div>
      <section className="banner-ImeiBlacklist py-md-2 pt-md-5 pt-0 pb-0 position-relative">
        <div className="container py-2 pt-md-4 px-3">
          <div className="row justify-content-center p-3">
            <div className="col-md-6 col-12 px-md-5 p-0">
              <h1 className="fw-semibold display-5 mt-md-5">
                {t('Icunhh1')}
              </h1>
              <div className="important-info-box p-3 p-md-4 mt-4">
                <h5 className="fw-semibold text-danger">{t("Icunhh2")}</h5>
                <p className="m-0 pt-3 pb-2">
                  {t("Icunpp2")}
                </p>
                <p>
                  {t("Icunpp3")}
                  <Link to={"/apple-full-info-check-gsx"} onClick={scrollToTop} className="px-1 text-dark fw-bold">
                    {t("Icunpps1")}
                  </Link>
                  {t("Icunpp4")}
                  <Link to={"/premium-blacklist-checker"} onClick={scrollToTop} className="px-1 text-dark fw-bold">
                    {t("Icunpps2")}
                  </Link>
                  {t("Icunpp5")}

                </p>
              </div>

            </div>
            <div className="col-12 col-md-5 p-0 mt-3 me-md-5">
              <>
                <Formik
                  initialValues={{ carrier: "", model: "", eSIM: "", imei: "", email: "", confirmation1: false, confirmation2: false, confirmation3: false }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ handleChange, values, handleBlur }) => (
                    <Form className="bg-white rounded form-border p-md-4 p-0">
                      <div className="card unlock-card bg-transparent border-0 my-md-0 my-1">
                        <div className="card-body">
                          <h4 className="fw-semibold mb-4">{t('FormHead1')}</h4>
                          <div>
                            <p className="mb-0 fw-semibold">{t('Icunp5')}</p>
                            <div className=" mt-1 rounded py-2">
                              <Select
                                className="form-select-carrier11 p-0 border-0"
                                id="icloud_unlock"
                                name="carrier"
                                options={carrierData?.map((carrier) => ({
                                  value: carrier,
                                  label: carrier.name,
                                }))}
                                value={carrierData?.find(
                                  (option) => option.value === values.carrier
                                )}
                                onChange={(selectedOption) => {
                                  handleCarrierChange(
                                    selectedOption,
                                    handleChange
                                  );
                                }}
                                onBlur={handleBlur}
                                isSearchable={true}
                                filterOption={(option, inputValue) => {
                                  const optionLabel = option.label.toLowerCase();
                                  const searchValue = inputValue.toLowerCase();
                                  return optionLabel.includes(searchValue);
                                }}
                                placeholder={t("Icunp22")}
                              />
                            </div>
                          </div>
                          <ErrorMessage name="carrier" component="div" className="error-message ps-5 ms-4" />
                          {values.carrier === "USA" && (
                            <>
                              <div className="col-12 col-md-12">
                                <h6 className="fw-semibold small-font">{t("Formlabel7")}</h6>
                                <div className="input-group mb-3 border flex-nowrap rounded-input py-1 d-flex justify-content-between">
                                  <label
                                    className="input-group-text border-0 bg-transparent"
                                    htmlFor="inputGroupSelect01"
                                  >
                                    <img
                                      loading="lazy"
                                      src="/images/ph_phone-call (1).svg"
                                      alt=""
                                    />
                                    <span class="mx-2mx-0 text-secondary"> | </span>
                                  </label>
                                  <Field
                                    type="text"
                                    className="form-control border-0 py-2"
                                    placeholder={t("FormField7")}
                                    aria-label={t("FormField7")}
                                    id="eSIM"
                                    name="eSIM"
                                    value={values.eSIM}
                                    onChange={handleChange}
                                  />
                                  <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        <ul>
                                          <p className="fw-bold">
                                            {t("FormField7Help")}
                                          </p>
                                        </ul>
                                        <ul class="ms-3">
                                          <li>{t("FormField71Help")}</li>
                                          <li>{t("FormField72Help")}</li>
                                          <li>{t("FormField73Help")}</li>
                                          <li>{t("FormField74Help")}</li>
                                          <li>{t("FormField75Help")}</li>

                                        </ul>
                                        <ul>
                                          <p className="fw-bold">
                                            {t("FormField76Help")}
                                          </p>
                                        </ul>
                                      </React.Fragment>
                                    }
                                    placement="top"
                                  >
                                    <IconButton>
                                      <HelpIcon
                                        style={{
                                          width: 32,
                                          height: 32,
                                          "margin-left": 10,
                                        }} />
                                    </IconButton>
                                  </HtmlTooltip>
                                </div>
                              </div>
                              <ErrorMessage name="eSIM" component="div" className="error-message ps-5 ms-4" />
                            </>
                          )}

                          <div className="d-md-flex gap-1 model-imei me-1 mt-md-3">
                            <div className="col-12 col-md-6">
                              <h6 className="fw-semibold small-font">{t("Formlabel3")}</h6>
                              <div className="input-group mb-3 border rounded-input d-flex justify-content-between flex-nowrap">
                                <label
                                  className="input-group-text py-0 ps-2 pe-0 border-0 bg-transparent"
                                  htmlFor="inputGroupSelect01"
                                >
                                  <img
                                    loading="lazy"
                                    src="/images/ph_phone-call.svg"
                                    alt="ph_phone-call"
                                    width={'20'}
                                  />
                                  <span class="mx-2mx-0 text-secondary"> | </span>
                                </label>
                                <Select
                                  className="form-select-carrier border-0"
                                  id="model"
                                  name="model"
                                  options={state.modelData?.map((model) => ({
                                    value: model,
                                    label: model.name,
                                  }))}
                                  value={state.modelData?.find(
                                    (option) => option.value.name === values.model
                                  )}
                                  onChange={(selectedOption) => {
                                    handleModelChange(
                                      selectedOption,
                                      handleChange
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  isSearchable={true}
                                  filterOption={(option, inputValue) => {
                                    const optionLabel = option.label.toLowerCase();
                                    const searchValue = inputValue.toLowerCase();
                                    return optionLabel.includes(searchValue);
                                  }}
                                  placeholder={t("FormField3")}
                                />

                              </div>
                              <ErrorMessage name="model" component="div" className="error-message ps-5 ms-4" />

                            </div>


                            <div className="col-12 col-md-6">
                              <h6 className="fw-semibold small-font">{t("Formlabel4")}</h6>
                              <div className="input-group mb-3 border flex-nowrap rounded-input py-1 d-flex justify-content-between">
                                <label
                                  className="input-group-text border-0 bg-transparent"
                                  htmlFor="inputGroupSelect01"
                                >
                                  <img
                                    loading="lazy"
                                    src="/images/ph_phone-call (1).svg"
                                    alt=""
                                  />
                                  <span class="mx-2mx-0 text-secondary"> | </span>
                                </label>
                                <Field
                                  type="text"
                                  className="form-control border-0 py-2"
                                  placeholder={t("FormField4")}
                                  aria-label="IMEI (15 digits)"
                                  id="imei"
                                  name="imei"
                                  value={values.imei}
                                  onChange={handleChange}
                                />

                              </div>
                              <ErrorMessage name="imei" component="div" className="error-message ps-5 ms-4" />

                            </div>

                          </div>
                          <div>
                            <p className="mb-0 fw-semibold">{t('Icunp7')}</p>
                            <div className="input-group border d-flex align-items-center p-2 rounded mb-3 bg-white w-100">
                              <label className="input-group-text bg-transparent border-0" htmlFor="email">
                                <img loading="lazy" src="/images/at-sign2.svg" alt="" />
                                <span className="mx-2mx-0 text-secondary"> | </span>
                              </label>
                              <Field
                                type="email"
                                className="form-control border-0 py-1"
                                placeholder={t("FormField5")}
                                aria-label={t("FormField5")}
                                id="email"
                                name="email"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <ErrorMessage name="email" component="div" className="error-message ps-5 ms-4" />

                          {/* ✅ Confirmation Section */}
                          <h6 className="fw-semibold small-font">{t("Formlabel8")}</h6>
                          <div className="form-check mt-3">
                            <Field
                              type="checkbox"
                              id="confirmation1"
                              name="confirmation1"
                              className="form-check-input"
                            />
                            <label htmlFor="confirmation1" className="form-check-label">
                            {t("Formlabel811")}
                              <Link to="/apple-full-info-check-gsx" className="text-dark fw-bold">
                                {t("Formlabel812")}
                              </Link>
                              {t("Formlabel813")}
                            </label>
                            <ErrorMessage name="confirmation1" component="div" className=" ps-5 ms-4 text-danger" />
                          </div>
                          <div className="form-check mt-3">
                            <Field
                              type="checkbox"
                              id="confirmation2"
                              name="confirmation2"
                              className="form-check-input"
                            />
                            <label htmlFor="confirmation2" className="form-check-label">
                            {t("Formlabel821")}
                              <Link to="/premium-blacklist-checker" className="text-dark fw-bold">
                                {t("Formlabel822")}
                              </Link>
                              {t("Formlabel823")}
                              <Link to="/premium-blacklist-checker" className="text-dark fw-bold">
                                {t("Formlabel824")}
                              </Link>

                            </label>
                            <ErrorMessage name="confirmation2" component="div" className=" ps-5 ms-4 text-danger" />
                          </div>
                          <div className="form-check mt-3 pb-3">
                            <Field
                              type="checkbox"
                              id="confirmation3"
                              name="confirmation3"
                              className="form-check-input"
                            />
                            <label htmlFor="confirmation" className="form-check-label">
                            {t("Formlabel831")}
                              <a href="/refund-policy" target="_blank" rel="noopener noreferrer" className="text-dark fw-bold">
                                {t("Formlabel832")}
                              </a>
                            </label>
                            <ErrorMessage name="confirmation3" component="div" className=" ps-5 ms-4 text-danger" />
                          </div>

                          <button type="submit" className="btn btn-lg mt-md-0 mt-2 w-100 unlock-btn">
                            {t("Button2")}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                <div>
                  <ReCAPTCHA
                    key={captchaKey}
                    className="recaptcha"
                    sitekey={"6LeLE9YqAAAAADOc0-cFTfmPBQRxgXbIGjXi-FQH"}
                    ref={captchaRef}
                    hl={currentLanguageCode}
                  />
                </div>
              </>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-md-2 py-md-5 pt-0 pb-0 how-to-get-bg-sec">
        <div className="container py-md-3 py-0">
          <div className="row justify-content-center p-3 pb-md-4">
            <div className="col-md-6 col-12 px-md-5 p-0">
              <h2 class="text-center display-6 fw-bold section-title mb-3">{t('htgsh1')}</h2>
            </div>
          </div>
          <div className="row justify-content-center gap-md-5 m-md-0 m-0">
            <div className="col-md-5 col-12 p-md-4 p-3 how-to-get-bg">
              <h5 className="pt-3">{t('htgsh2')}</h5>
              <p>{t('htgsh3')}</p>
              <h4 className="fw-semibold py-2">{t('htgsh4')}</h4>
              <p>{t('htgshh1')}<Link to={'/apple-full-info-check-gsx'} onClick={scrollToTop} className="px-1 text-secondary">{t('htgshh2')}</Link>{t('htgshh3')}</p>
              <h4 className="fw-semibold pt-2 mb-0">{t('htgsh6')}</h4>
              <ul class="choose-us-list py-2 ps-3">
                <li class="ps-2 list-title pb-1">
                  <span class="imeiblacklist1 fw-bold pb-2">{t('htgsh7')}</span>
                  <p class="pt-2 mb-0">{t('htgsh8')}</p>
                </li>
                <li class="ps-2 list-title pb-1">
                  <span class="imeiblacklist1 fw-bold">{t('htgsh9')}</span>
                  <p>{t('htgsh10')}<Link to={'/apple-full-info-check-gsx'} onClick={scrollToTop} className="px-1 text-secondary">{t('htgsh101')}</Link>{t('htgsh102')}</p>
                </li>
                <li class="ps-2 list-title pb-1">
                  <span class="imeiblacklist1 fw-bold">{t('htgsh11')}</span>
                  <p class="pt-2 mb-0">{t('htgshh4')}<Link to={'/apple-full-info-check-gsx'} onClick={scrollToTop} className="px-1 text-secondary">{t('htgshh5')}</Link>{t('htgshh6')}</p>
                </li>
                <li class="ps-2 list-title pb-1">
                  <span class="imeiblacklist1 fw-bold">{t('htgsh13')}</span>
                  <p class="pt-2 mb-0">{t('htgsh14')}<Link to={'/premium-blacklist-checker'} onClick={scrollToTop} className="px-1 text-secondary">{t('htgsh141')}</Link>{t('htgsh142')}</p>
                </li>
                <li class="ps-2 list-title pb-1">
                  <span class="imeiblacklist1 fw-bold">{t('htgsh15')}</span>
                  <p class="pt-2 mb-0">{t('htgsh16')}</p>
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-12 p-md-4 p-3 how-to-get-bg">
              <div className="row justify-content-center">
                <div className="col-12 px-md-4 py-md-5 p-3 how-to-get-bg2">
                  <h5 className="pt-md-3">{t('htgsh17')}</h5>
                  <h4 className="fw-semibold py-2">{t('htgsh18')}</h4>
                  <p className="mb-0">{t('htgsh19')}</p>
                  <ul className="submit-your-information ps-3">
                    <li className="m-0 "><p>{t('htgsh20')}</p></li>
                  </ul>
                </div>
                <div className="col-12 px-md-4 py-md-5 p-3 how-to-get-bg1">
                  <h5 className="pt-md-4">{t('htgsh21')}</h5>
                  <h4 className="fw-semibold py-2">{t('htgsh22')}</h4>
                  <p>{t('htgsh23')}</p>
                </div>
                <div className="col-12 px-md-4 py-md-4 p-3 how-to-get-bg2">
                  <h5 className="pt-md-4">{t('htgsh24')}</h5>
                  <h4 className="fw-semibold py-2">{t('htgsh25')}</h4>
                  <p className="mb-0">{t('htgsh26')}</p>
                  <ul className="submit-your-information ps-3">
                    <li className="m-0 "><p>{t('htgsh27')}</p></li>
                    <li className="m-0 "><p>{t('htgsh28')}<Link to={'/track-order'} onClick={scrollToTop} className="px-1 text-secondary">{t('htgshh7')}</Link></p></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ICloudUnlock;
